<template>
  <div>
    <el-card>
      <el-tag
        size="medium"
        style="
          margin-bottom: 50px;
          line-height: 30px;
          width: 90px;
          height: 30px;
          text-align: center;
          font-size: 14px;
        "
        >系统设置</el-tag
      >
      <el-form ref="form" :model="sysConfig" label-width="130px">
        <el-form-item label="举报邮箱" prop="reportMailbox">
          <el-input v-model="sysConfig.reportMailbox" />
        </el-form-item>
        <el-form-item label="公安备案" prop="keepRecord">
          <el-input v-model="sysConfig.keepRecord" />
        </el-form-item>
        <el-form-item label="ICP备案" prop="ipc">
          <el-input v-model="sysConfig.ipc" />
        </el-form-item>
        <el-form-item label="ICP证" prop="ipc">
          <el-input v-model="sysConfig.ipcCard" />
        </el-form-item>
        <el-form-item label="icp证链接" prop="ipc">
          <el-input v-model="sysConfig.ipc" />
        </el-form-item>
        <el-form-item label="版权所有" prop="copyright">
          <el-input v-model="sysConfig.copyright" />
        </el-form-item>
        <el-form-item label="商务邮箱1" prop="copyright">
          <el-input v-model="sysConfig.businessEmail" />
        </el-form-item>
        <el-form-item label="商务邮箱2" prop="copyright">
          <el-input v-model="sysConfig.businessEmailTwo" />
        </el-form-item>
        <el-form-item label="公司联系地址" prop="copyright">
          <el-input v-model="sysConfig.companyAddress" />
        </el-form-item>
        <el-form-item label="红包链接" prop="copyright">
          <el-input v-model="sysConfig.redPackageUrl" />
        </el-form-item>
        <el-form-item label="logo">
          <!-- <form
            action="/index/upload"
            enctype="multipart/form-data"
            method="post"
          >
            <input type="file" name="image" class="upload" @change="file"/> <br />
            <img :src="imgsrc" alt="" class="img"/>
            <input type="submit" value="上传" style="opacity: 0" ref="sde"/>
          </form> -->
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
          >
            <img
              v-if="sysConfig.logo"
              :src="baseurl + yu + sysConfig.logo"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
            <div slot="tip" class="el-upload__tip waring">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="微信公众号" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_1"
            :data="action"
          >
            <img
              v-if="sysConfig.wxOfficial"
              :src="baseurl + yu + sysConfig.wxOfficial"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
            <div slot="tip" class="el-upload__tip waring">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="小程序二维码上传" prop="copyright">
          <imgup
            :tip_text="false"
            :action="action"
            :banner="sysConfig.miniProgram"
            @event="handleAvatarSuccess_6"
          />
        </el-form-item>
        <el-form-item label="小助手" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_2"
            :data="action"
          >
            <img
              v-if="sysConfig.assistant"
              :src="baseurl + yu + sysConfig.assistant"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
            <div slot="tip" class="el-upload__tip waring">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="商务合作1" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_3"
            :data="action"
          >
            <img
              v-if="sysConfig.cooperation"
              :src="baseurl + yu + sysConfig.cooperation"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
            <div slot="tip" class="el-upload__tip waring">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="商务合作2" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_5"
            :data="action"
          >
            <img
              v-if="sysConfig.cooperationTwo"
              :src="baseurl + yu + sysConfig.cooperationTwo"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
            <div slot="tip" class="el-upload__tip waring">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="媒体合作" prop="copyright">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,.svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_4"
            :data="action"
          >
            <img
              v-if="sysConfig.cooperativePartner"
              :src="baseurl + yu + sysConfig.cooperativePartner"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
            <div slot="tip" class="el-upload__tip waring">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="社区">
          <el-button style="margin-top: 12px;" @click="newImport" type="primary"
            >新增</el-button
          >
          <el-table :data="grounp" style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="{ row }">
                <el-form label-position="top" class="demo-table-expand">
                  <el-form-item label="描述">
                    <el-input v-model="row.title"></el-input>
                  </el-form-item>
                  <el-form-item label="封面">
                    <up-img
                      :banner.sync="row.picture"
                      :action="action"
                      fullUrl
                    ></up-img>
                  </el-form-item>
                  <el-form-item label="二维码">
                    <up-img
                      :banner.sync="row.qrcode"
                      fullUrl
                      :action="action"
                    ></up-img>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column label="描述" prop="title"> </el-table-column>
            <el-table-column label="封面" prop="picture">
              <template slot-scope="{ row }">
                <p-image :src="row.picture"></p-image>
              </template>
            </el-table-column>
            <el-table-column label="二维码" prop="qrcode">
              <template slot-scope="{ row }">
                <p-image :src="row.qrcode"></p-image>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="deleteRow(scope.$index)"
                  type="text"
                  size="small"
                >
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-button type="primary" style="margin-top: 50px" @click="editform">{{
          btn ? '提交修改' : 'loading..'
        }}</el-button>
        <el-button
          type="primary"
          style="margin-top: 50px; width: 98px"
          @click="rest"
          >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
        >
      </el-form>
    </el-card>
    <pop ref="pop" @toAudit="onSubmit">
      <el-form
        label-position="left"
        label-width="auto"
        class="demo-table-expand"
      >
        <el-form-item label="描述">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="封面">
          <up-img :banner.sync="form.picture" :action="action" fullUrl></up-img>
        </el-form-item>
        <el-form-item label="二维码">
          <up-img :banner.sync="form.qrcode" fullUrl :action="action"></up-img>
        </el-form-item>
      </el-form>
    </pop>
  </div>
</template>

<script>
import $ from 'jquery'
// import {WebUploader} from '../../assets/webuploader'

export default {
  components: {
    imgup: () => import('@/components/upImg/index.vue')
  },
  data() {
    return {
      btn: true,
      action: {
        action: 'system'
      },
      grounp: [
        // {
        //   title: '描述',
        //   picture:
        //     'https://static-cdn.yfchuhai.com/test.yfchuhai.com/uploads/system/20220804/8f7e1f09d234d3f91c41e423cf7333f6.jpg',
        //   qrcode:
        //     'https://static-cdn.yfchuhai.com/test.yfchuhai.com/uploads/system/20220804/324193440d71f9f98b17e6f85188d93f.jpg'
        // }
      ],
      sysConfig: {
        logo: '',
        keepRecord: '',
        ipc: '',
        ipcCard:'',
        copyright: '',
        email: '',
        wxOfficial: '',
        assistant: '',
        cooperation: '',
        businessEmail: '',
        companyAddress: '',
        cooperativePartner: '',
        cooperationTwo: '',
        businessEmailTwo: ''
      },
      form: {},
      imageUrl: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain
    }
  },
  created() {
    this.getform()
    sessionStorage.setItem('action', 'system')
  },
  mounted: function() {},
  methods: {
    rest() {
      // this.form={
      //   logo: '',
      //   keep_record: '',
      //   ipc: '',
      //   copyright: '',
      // }
      this.$refs.form.resetFields()
      this.getform()
    },
    getform() {
      this.$http.get('/admin/SysConfig/getDetail').then(({ data: res }) => {
        // console.log(res)
        if (res.errorCode == 200) {
          if (res.data.sys_config) {
            this.sysConfig = res.data.sys_config
            this.grounp = JSON.parse(res.data.sys_config.group)
          }
        }
      })
    },
    newImport() {
      console.log(this.grounp.length)
      if (this.grounp.length >= 5) {
        this.$message.error('最多添加5个')
        return
      }
      this.$refs.pop.show = true
    },
    onSubmit() {
      this.$refs.pop.show = false
      this.grounp.push(this.form)
      this.form = {}
    },
    deleteRow(val) {
      this.grounp.splice(val, 1)
    },
    handleAvatarSuccess_1(res) {
      this.sysConfig.wxOfficial = res.data.url
      this.$forceUpdate()
    },
    handleAvatarSuccess_2(res) {
      this.sysConfig.assistant = res.data.url
      this.$forceUpdate()
    },
    handleAvatarSuccess_3(res) {
      this.sysConfig.cooperation = res.data.url
      this.$forceUpdate()
    },
    handleAvatarSuccess_4(res) {
      this.sysConfig.cooperativePartner = res.data.url
      this.$forceUpdate()
    },
    handleAvatarSuccess_5(res) {
      this.sysConfig.cooperationTwo = res.data.url
      this.$forceUpdate()
    },
    handleAvatarSuccess_6(res) {
      this.sysConfig.miniProgram = res
      this.$forceUpdate()
    },
    editform() {
      if (this.btn) {
        // this.sysConfig.logo = this.sysConfig.logo.slice(this.baseurl.length)
        this.$http
          .post('/admin/SysConfig/editSysConfig', {
            sysConfig: {
              ...this.sysConfig,
              group: JSON.stringify(this.grounp)
            }
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              $(this.$refs.sde).click()

              this.getform()
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    handleAvatarSuccess(res, file) {
      this.sysConfig.logo = res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.img {
  // width: 500px;
  height: 200px;
  width: auto;
  // height: 200px;
  border: 0;
  outline: 0;
}
img[src=''] {
  opacity: 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 178px;
  width: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  display: block;
  max-width: 100%;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
</style>
